// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-experience-tsx": () => import("../src/pages/experience.tsx" /* webpackChunkName: "component---src-pages-experience-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-style-color-tsx": () => import("../src/pages/style/color.tsx" /* webpackChunkName: "component---src-pages-style-color-tsx" */),
  "component---src-pages-style-index-tsx": () => import("../src/pages/style/index.tsx" /* webpackChunkName: "component---src-pages-style-index-tsx" */),
  "component---src-pages-style-sample-tsx": () => import("../src/pages/style/sample.tsx" /* webpackChunkName: "component---src-pages-style-sample-tsx" */),
  "component---src-pages-style-typography-tsx": () => import("../src/pages/style/typography.tsx" /* webpackChunkName: "component---src-pages-style-typography-tsx" */),
  "component---src-pages-work-tsx": () => import("../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

